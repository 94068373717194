import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article6 from '../../components/parent/article6'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article6/article-header6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Różnice finansowe w klasie – jak uczyć dzieci szacunku do wszystkich"
        description="Różnice finansowe w klasie – jak uczyć dzieci szacunku do wszystkich"
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={
          <>
            Różnice finansowe
            <br />w{'\u00A0'}klasie – jak{'\u00A0'}uczyć
            <br />
            dzieci szacunku
            <br />
            do{'\u00A0'}wszystkich
          </>
        }
        desc={undefined}
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article6 />
      <UsefulTools
        items={[
          {
            title: 'Gry',
            subtitle: 'Finansowe kalambury',
            image: data.Tool1,
            link: '/parent/Finansowe%20kalambury.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Kolorowanka',
            image: data.Tool2,
            link: '/parent/Kolorowanka%201.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Rebusy',
            image: data.Tool2,
            link: '/parent/Rebusy.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
