import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article6/person6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(relativePath: { eq: "parent/article6/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article6/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1020) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Aleksandra Piotrkowska - TBC"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Aleksandra Piotrkowska</span>{' '}
                {/*<span className="personPosition">TBC</span>*/}
                <br />
                <ArticleAboutModal>
                  Aleksandra Piotrowska, doktor psychologii. Pracownik naukowy
                  Wydziału Pedagogicznego Uniwersytetu Warszawskiego (obecnie na
                  emeryturze), konsultant dla rodziców i{'\u00A0'}nauczycieli.
                  Współdziałała jako społeczny doradca z{'\u00A0'}Komitetem
                  Ochrony Praw Dziecka i{'\u00A0'}Rzecznikiem Praw Dziecka (w
                  {'\u00A0'}latach 2008 - 2018). Zajmuje się także propagowaniem
                  wiedzy psychologicznej, współpracując w{'\u00A0'}tym zakresie
                  jako ekspert z prasą, a{'\u00A0'}także ze
                  {'\u00A0'}stacjami radiowymi oraz telewizyjnymi. Autorka wielu
                  publikacji naukowych i popularyzatorskich, w{'\u00A0'}tym
                  książek „Szczęśliwe dziecko, czyli jak uniknąć najczęstszych
                  błędów wychowawczych”, „Nastolatki pod lupą”, „Między nami
                  samicami” (współ. Dorota Sumińska). Prywatnie nałogowy
                  czytelnik literatury wszelakiej, miłośniczka wyjazdów
                  narciarskich i{'\u00A0'}podróży. Matka dwójki dzieci i
                  {'\u00A0'}babcia trójki wnucząt.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Konieczność wprowadzania współczesnych dzieci w{'\u00A0'}świat
                pieniądza jest dziś bezdyskusyjna. Rzeczywistość wymaga
                sprawnego poruszania się w{'\u00A0'}kwestiach finansowych, a
                {'\u00A0'}konsekwencje braku chociaż podstawowej wiedzy i
                {'\u00A0'}umiejętności zarządzania własnymi finansami mogą być
                tragiczne dla jednostek i{'\u00A0'}całych rodzin. Rodzice
                powinni dążyć do tego, aby już dzieci w{'\u00A0'}okresie
                przedszkolnym rozumiały związek pieniędzy z{'\u00A0'}pracą i
                {'\u00A0'}miały świadomość ograniczoności zasobów finansowych
                każdej rodziny. Nie wolno zatem izolować dzieci od{'\u00A0'}
                problematyki finansów – wprost przeciwnie, powinny się z
                {'\u00A0'}nią stykać np. w{'\u00A0'}związku z{'\u00A0'}
                planowaniem wydatków rodzinnych i{'\u00A0'}koniecznością
                ograniczeń w{'\u00A0'}tym zakresie.
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row>
            <Col xs={12}>
              <p>
                Rzecz w{'\u00A0'}tym, aby następstwem dążeń rodziców do
                {'\u00A0'}zapoznawania dzieci z{'\u00A0'}problematyką pieniądza
                nie stało się ukształtowanie u{'\u00A0'}dzieci przeświadczenia,
                że{'\u00A0'}zdobywanie i{'\u00A0'}posiadanie pieniędzy to
                {'\u00A0'}najważniejszy cel życia ludzkiego, a{'\u00A0'}o
                {'\u00A0'}wartości człowieka decyduje wielkość zasobów
                finansowych, jakie ma{'\u00A0'}do{'\u00A0'}dyspozycji. Dziecko
                spotyka przecież w{'\u00A0'}przedszkolu, szkole, na{'\u00A0'}
                placu zabaw rówieśników z{'\u00A0'}różnie zasobnych rodzin i
                {'\u00A0'}ze{'\u00A0'}wszystkimi powinno umieć zgodnie żyć. W
                {'\u00A0'}szkole podstawowej (i{'\u00A0'}nie tylko) dzieci
                niestety oceniane są{'\u00A0'}ze{'\u00A0'}względu na{'\u00A0'}
                posiadane przedmioty (ubranie, obuwie, akcesoria szkolne,
                telefon itp.). Częste jest wywyższanie się uczniów o{'\u00A0'}
                wysokim statusie materialnym, podkreślanie różnic, wyśmiewanie
                niezamożnych kolegów, wykluczanie z{'\u00A0'}pewnych aktywności
                (np. spotkań poza szkołą, urodzin itp.).
              </p>
            </Col>
            <Col className="marginTop30" xs={12}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col className="marginTop30" xs={12}>
              <p>
                Co{'\u00A0'}zrobić, aby uczyć dziecko szacunku do{'\u00A0'}
                wszystkich, niezależnie od{'\u00A0'}istniejących różnic
                finansowych? Najczęściej kojarzymy oddziaływania wychowawcze z
                {'\u00A0'}tłumaczeniem, wyjaśnianiem, przekonywaniem, krótko
                mówiąc: zdajemy się na{'\u00A0'}moc słowa. Z{'\u00A0'}dzieckiem
                należy oczywiście rozmawiać o{'\u00A0'}tym, że{'\u00A0'}ludzie
                różnią się pod wieloma względami – są{'\u00A0'}wysocy i
                {'\u00A0'}niscy, szczupli i{'\u00A0'}grubi, młodzi i{'\u00A0'}
                starzy, różnią się religią, miejscem pochodzenia, wielkością
                mieszkań, w{'\u00A0'}jakich żyją, posiadanymi przedmiotami. I
                {'\u00A0'}te różnice są{'\u00A0'}czymś normalnym. Ale wszyscy
                zasługują na{'\u00A0'}szacunek z{'\u00A0'}naszej strony, na
                życzliwość, zainteresowanie, grzeczne i{'\u00A0'}uprzejme
                traktowanie. Problem tkwi w{'\u00A0'}tym, że{'\u00A0'}szacunku
                wobec innych nie przekaże się dziecku na zasadzie kolejnego
                polecenia (umyj ręce, zabierz ze stołu swoje rzeczy, wyjdź z
                {'\u00A0'}psem, szanuj wszystkich ludzi). Rozmowy i{'\u00A0'}
                tłumaczenie mają oczywiście swoje miejsce w{'\u00A0'}wychowaniu,
                ale największe znaczenie w{'\u00A0'}rozwoju ma{'\u00A0'}uczenie
                się przez naśladownictwo – przejmowanie (najczęściej bezwiedne)
                przez dziecko zachowań obserwowanych u{'\u00A0'}innych osób,
                przede wszystkim tych najbliższych dziecku, najważniejszych dla
                niego, czyli rodziców. Dzieci robią{'\u00A0'}to, co{'\u00A0'}my
                robimy, a{'\u00A0'}nie{'\u00A0'}to, co{'\u00A0'}im{'\u00A0'}
                mówimy, że{'\u00A0'}mają robić. Dlatego jeśli chcemy, aby nasze
                dziecko traktowało z{'\u00A0'}szacunkiem wszystkich swoich
                kolegów, niezależnie od{'\u00A0'}ich zamożności, to{'\u00A0'}
                musimy bardzo dokładnie przeanalizować swoje zachowania wobec
                różnych ludzi, a{'\u00A0'}także wygłaszane przez siebie uwagi i
                {'\u00A0'}opinie.
              </p>
            </Col>
            <Col className="marginTop30" xs={12}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col className="marginTop30" xs={12}>
              <p>
                Czy wychodząc z{'\u00A0'}dzieckiem z{'\u00A0'}domu, z{'\u00A0'}
                taką samą uprzejmością i{'\u00A0'}uwagą rozmawiamy ze{'\u00A0'}
                sprzątaczką czy portierem jak z{'\u00A0'}sąsiadem – właścicielem
                dużej i{'\u00A0'}dochodowej firmy? Czy wioząc dziecko do
                {'\u00A0'}szkoły, nie traktujemy lekceważąco, z{'\u00A0'}góry
                osób w{'\u00A0'}niedużych lub starych samochodach, nie
                wypowiadamy się o{'\u00A0'}nich pogardliwie, nie wyśmiewamy
                („samochód starszy od kierowcy”)? Czy rozmawiając przy dziecku o
                {'\u00A0'}krewnych lub znajomych, nie podkreślamy z{'\u00A0'}
                zachwytem i{'\u00A0'}uznaniem ich zarobków i{'\u00A0'}możliwości
                finansowych, pomijając np. ich wiedzę, kompetencje czy
                przedsiębiorczość? Czy nie zachęcamy dziecka do{'\u00A0'}
                spędzania czasu wspólnie z{'\u00A0'}dziećmi z{'\u00A0'}zamożnych
                rodzin, wykluczając kontakty z{'\u00A0'}dziećmi uboższymi? Czy
                rozmawiając z{'\u00A0'}dzieckiem o{'\u00A0'}prezentach
                otrzymanych na jego urodzinowym przyjęciu, nie analizujemy ich z
                {'\u00A0'}punktu widzenia domniemanej ceny (a{'\u00A0'}pomijamy
                np. pomysłowość czy trafienie w{'\u00A0'}gust dziecka)? Czy
                słuchając opowieści dziecka o{'\u00A0'}różnych sposobach
                spędzania wakacji przez jego rówieśników, nie podkreślamy
                jedynie związanych z{'\u00A0'}tym wydatków (oczywiście najlepsze
                wakacje to{'\u00A0'}te{'\u00A0'}najdroższe)? Czy w{'\u00A0'}
                trakcie spaceru z{'\u00A0'}dzieckiem nie komentujemy mijanych
                rowerków ze{'\u00A0'}względu na markę i{'\u00A0'}„stopień
                wypasienia”?
              </p>
              <p>
                To{'\u00A0'}tylko kilka przykładów sytuacji, w{'\u00A0'}których
                realizowane przez rodziców zachowania wpływają na{'\u00A0'}
                dziecko silniej niż wygłaszane przez nich tyrady na{'\u00A0'}
                temat właściwego postępowania. Oczywiście rodzice nie są
                {'\u00A0'}jedynym wzorem naśladowanym przez dzieci, ale przez
                wiele lat (co{'\u00A0'}najmniej do{'\u00A0'}okresu dojrzewania)
                są{'\u00A0'}wzorem najważniejszym, w{'\u00A0'}decydującym
                stopniu kształtującym przekonania i{'\u00A0'}zachowania
                potomków.
              </p>
              <p>Autor: Aleksandra Piotrkowska</p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
